import { gql } from '@apollo/client';
import { storePersistor } from 'configuration/redux/store';
import { useTopbarMessage } from 'modules/common/components/message-top-bar/hooks/use-topbar-message.hook';
import { clearCookie, getCookie, setCookie } from 'modules/common/utils/cookie';
import { requestGql } from 'modules/common/utils/request-gql';
import { CurrentBusinessPartnerInternalCookieName } from 'modules/current-business-partner-selector/constants/current-business-partner-internal-cookie-name';
import { useFetchCompanies } from 'modules/user-company-management/hooks';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface CurrentBusinessPartnerInternalInterface {
  id: string;
  name: string;
  kunnr?: string;
}

export interface CurrentBusinessPartnerInternalFlatInterface extends CurrentBusinessPartnerInternalInterface {
  totalCount?: number;
}

interface CurrentBusinessPartnerInternalFetchInterface {
  data: { businessPartnerInternal: CurrentBusinessPartnerInternalInterface };
  totalCount: number;
}

const fetchUserCurrentBusinessPartnerInternal = (): Promise<CurrentBusinessPartnerInternalFetchInterface> =>
  requestGql(
    {
      query: gql`
        query {
          userCurrentBusinessPartnerInternal {
            data {
              businessPartnerInternal {
                id
                name
                kunnr
              }
            }
            totalCount
          }
        }
      `,
      variables: {},
    },
    null,
    'userCurrentBusinessPartnerInternal',
  );

const setUserCurrentBusinessPartnerInternal = (
  currentBusinessPartnerInternalId?: string,
): Promise<CurrentBusinessPartnerInternalFetchInterface> =>
  requestGql(
    {
      mutation: gql`
        mutation SetUserCurrentBusinessPartnerInternal($currentBusinessPartnerInternalId: ID) {
          setUserCurrentBusinessPartnerInternal(currentBusinessPartnerInternalId: $currentBusinessPartnerInternalId) {
            data {
              businessPartnerInternal {
                id
                name
                kunnr
              }
            }
            totalCount
          }
        }
      `,
      variables: {
        currentBusinessPartnerInternalId,
      },
    },
    null,
    'setUserCurrentBusinessPartnerInternal',
  );

export const useCurrentBusinessPartnerInternal = (reloadOnChange = true) => {
  const router = useRouter();
  const kunnrQuery = router.query?.kunnr as string;
  const { showTopbarMessage, hideTopbarMessage } = useTopbarMessage();
  const { t } = useTranslation();
  const { fetchCompanies } = useFetchCompanies();
  const [currentBusinessPartnerInternal, setCurrentBusinessPartnerInternal] =
    useState<CurrentBusinessPartnerInternalFlatInterface | null>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (kunnrQuery && currentBusinessPartnerInternal?.kunnr && (kunnrQuery !== currentBusinessPartnerInternal?.kunnr)) {
      void changeCurrentBusinessPartnerInternalByKunnr(kunnrQuery, false)
    }
    if (kunnrQuery) {
      const { kunnr, ...otherFields } = router.query;
      void router.push({
        pathname: router.pathname,
        query: otherFields
      })
    }
  }, [kunnrQuery, currentBusinessPartnerInternal?.kunnr]);

  const setCurrentBusinessPartnerInternalLocal = (value?: CurrentBusinessPartnerInternalFlatInterface) => {
    if (value?.id) {
      const { id, name, totalCount, kunnr } = value;
      setCookie(CurrentBusinessPartnerInternalCookieName, JSON.stringify({ id, name, totalCount, kunnr }));
      setCurrentBusinessPartnerInternal(value);
      void storePersistor.purge();
    } else if (value) {
      const { totalCount } = value;
      setCookie(CurrentBusinessPartnerInternalCookieName, JSON.stringify({ totalCount }));
      setCurrentBusinessPartnerInternal(null);
      void storePersistor.purge();
    }
  };

  const changeCurrentBusinessPartnerInternalByKunnr = useCallback(async (kunnr?: string, shouldReload = true): Promise<void> => {
    const companies = await fetchCompanies({ filter: { kunnr: { equalTo: kunnr } } });
    const businessPartnrInternal = companies?.data?.[0];
    if (businessPartnrInternal?.kunnr) {
      await changeCurrentBusinessPartnerInternal({
        id: businessPartnrInternal?.id,
        name: businessPartnrInternal?.name,
        kunnr: businessPartnrInternal?.kunnr
      },
        shouldReload
      )
    }
  }, [currentBusinessPartnerInternal?.kunnr]);

  const changeCurrentBusinessPartnerInternal = async (value: CurrentBusinessPartnerInternalFlatInterface | null, shouldReload = true) => {
    try {
      hideTopbarMessage();
      setIsLoading(true);
      const remoteValue = await setUserCurrentBusinessPartnerInternal(value?.id);

      setCurrentBusinessPartnerInternalLocal({
        ...remoteValue?.data?.businessPartnerInternal,
        totalCount: remoteValue.totalCount,
      });

      if (reloadOnChange && shouldReload) {
        router.reload();
      }
    } catch (e) {
      showTopbarMessage({ title: t('errors.couldnt-set-current-business-partner'), severity: 'error' });
      console.error(e);
    }
    setIsLoading(false);
  };

  const resetCurrentBusinessPartnerInternal = async () => {
    await setUserCurrentBusinessPartnerInternal(null);
    setCurrentBusinessPartnerInternal(null);
    clearCookie(CurrentBusinessPartnerInternalCookieName);
    void storePersistor.purge();
  }

  const getCurrentBusinessPartnerInternal = async () => {
    setIsLoading(true);
    const valueFromCookie = getCookie(CurrentBusinessPartnerInternalCookieName);
    let result: CurrentBusinessPartnerInternalFlatInterface;

    if (valueFromCookie) {
      result = JSON.parse(valueFromCookie);
    } else {
      try {
        const remoteValue = await fetchUserCurrentBusinessPartnerInternal();

        if (remoteValue) {
          result = { ...remoteValue.data.businessPartnerInternal, totalCount: remoteValue.totalCount };
        }
      } catch (e) {
        result = null;
      }
    }

    setIsLoading(false);

    setCurrentBusinessPartnerInternalLocal(result);
  };

  useEffect(() => {
    void getCurrentBusinessPartnerInternal();
  }, []);

  return {
    currentBusinessPartnerInternal,
    changeCurrentBusinessPartnerInternal,
    isLoading,
    resetCurrentBusinessPartnerInternal,
    changeCurrentBusinessPartnerInternalByKunnr
  };
};
